import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
import { ContentSidePanelRestauracia } from "../../components/Content";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Content side={<ContentSidePanelRestauracia mdxType="ContentSidePanelRestauracia" />} mdxType="Content">
      <p>{`Každý deň pripravujeme čerstvé mňamky. Domáca kuchyňa je naša špecialita :)`}</p>
      <p>{`Máme radi naše slovenské jedlá, pripravené doma v kuchyni a s láskou. A práve také vám budeme servírovať.
Pani kuchárka varí veľmi rada a to, že varí s láskou a úsmevom ochutnáte sami :)`}</p>
      <p>{`Pre našich ubytovaných hostí ponúkame možnosť polpenzie, ktorá zahŕňa raňajky a večere.
Návštevníci strediska Vernár si môžu pochutnať na výborných obedoch, počas ktorých servírujeme denné menu.
Samozrejme, náš bar s bohatou ponukou teplých aj studených nápojov vám je k dispozícii počas celého dňa.`}</p>
      <h2>{`Raňajky sú základ dňa`}</h2>
      <p>{`Pre našich hostí máme pripravené chutné raňajky, ktoré servírujeme formou bufetu.
Čerstvé pečivo, zeleninka ani ovocie nebudú chýbať v našej bufetovej ponuke.
Horúci čaj či káva zahrejú a prebudia veľkých aj malých návštevníkov.`}</p>
      <h2>{`Obedy formou menu`}</h2>
      <p>{`Počas obeda máme nielen pre našich hotelových hostí, ale pre všetkých návštevníkov strediska Vernár,
pripravené bohaté denné menu. Horúca polievka zahreje a hlavné jedlo si môžete vybrať podľa vlastnej
chuti - každý deň na výber až zo 4 jedál. A obed zakončíme sladkým dezertom. Na obedovom menu si u nás
môžete pochutnať za zvýhodnenú cenu.`}</p>
      <h2>{`Večere`}</h2>
      <p>{`Bodku za skvelým dňom dá skvelá večera :) Pre našich hostí servírujeme večere formou švédskych stolov alebo
formou výberu z menu. Základom je však vždy bohatý výber :)`}</p>
      <h2>{`Sladký dezert “od babičky”`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAXT1KCzQCv/EABgQAQEBAQEAAAAAAAAAAAAAAAIDABES/9oACAEBAAEFAqCQyCW6d0LTkou9/df/xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAwEBPwFJv//EABURAQEAAAAAAAAAAAAAAAAAABEA/9oACAECAQE/ARi//8QAHhAAAgEDBQAAAAAAAAAAAAAAAAECAxEhEiIjMZH/2gAIAQEABj8CSSscfhup5HHqTNU3aKHKKwf/xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMVFxof/aAAgBAQABPyGA3gSWHA6OXfCtzjiwgtoe34QqT8VH/9oADAMBAAIAAwAAABCX/wD/xAAXEQEBAQEAAAAAAAAAAAAAAAABABHw/9oACAEDAQE/ENDIPF//xAAXEQEBAQEAAAAAAAAAAAAAAAABABHw/9oACAECAQE/EELbfN//xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhMVFxQWHB/9oACAEBAAE/ELFBaGr25Z3IG76fmEIgYAGuKToopvT4wYwqXWnDDmAG+QJXvP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cd6ac082b3340cf09a1168958ac315a4/e93cc/69568565_1150473825150497_7298028233457401856_o.webp 300w", "/static/cd6ac082b3340cf09a1168958ac315a4/b0544/69568565_1150473825150497_7298028233457401856_o.webp 600w", "/static/cd6ac082b3340cf09a1168958ac315a4/68fc1/69568565_1150473825150497_7298028233457401856_o.webp 1200w", "/static/cd6ac082b3340cf09a1168958ac315a4/a2303/69568565_1150473825150497_7298028233457401856_o.webp 1800w", "/static/cd6ac082b3340cf09a1168958ac315a4/7c4f2/69568565_1150473825150497_7298028233457401856_o.webp 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cd6ac082b3340cf09a1168958ac315a4/73b64/69568565_1150473825150497_7298028233457401856_o.jpg 300w", "/static/cd6ac082b3340cf09a1168958ac315a4/3ad8d/69568565_1150473825150497_7298028233457401856_o.jpg 600w", "/static/cd6ac082b3340cf09a1168958ac315a4/6c738/69568565_1150473825150497_7298028233457401856_o.jpg 1200w", "/static/cd6ac082b3340cf09a1168958ac315a4/8b34c/69568565_1150473825150497_7298028233457401856_o.jpg 1800w", "/static/cd6ac082b3340cf09a1168958ac315a4/8fe94/69568565_1150473825150497_7298028233457401856_o.jpg 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cd6ac082b3340cf09a1168958ac315a4/6c738/69568565_1150473825150497_7298028233457401856_o.jpg",
              "alt": "69568565 1150473825150497 7298028233457401856 o",
              "title": "69568565 1150473825150497 7298028233457401856 o",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p>{`Za výborným jedlom alebo ku poobedňajšej kávičke na dobitie energie. Sladký domáci dezert od našej pani
kuchárky poteší malých aj veľkých maškrtníkov.`}</p>
      <h2>{`Svadby a rodinné oslavy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGehjRUYR//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIRAxAx/9oACAEBAAEFAo4xI4Riqqtf/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAYEAADAQEAAAAAAAAAAAAAAAABEFERMf/aAAgBAQAGPwIQri2L/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERIVFBYXH/2gAIAQEAAT8hZKi6ClZv1EWXTPEVCSuVg6vd3T//2gAMAwEAAgADAAAAEFD/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxDSv//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/ECH/xAAcEAEBAAICAwAAAAAAAAAAAAABIQARMUFRYYH/2gAIAQEAAT8QKjA2K9d5CN+Fh8MIoHMI+MBojoHrGdAjniS4lunRFtz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0ff237757982b8ae18e6bc5e1aee8001/e93cc/69873342_1143855435812336_7965630060092719104_o.webp 300w", "/static/0ff237757982b8ae18e6bc5e1aee8001/b0544/69873342_1143855435812336_7965630060092719104_o.webp 600w", "/static/0ff237757982b8ae18e6bc5e1aee8001/68fc1/69873342_1143855435812336_7965630060092719104_o.webp 1200w", "/static/0ff237757982b8ae18e6bc5e1aee8001/a2303/69873342_1143855435812336_7965630060092719104_o.webp 1800w", "/static/0ff237757982b8ae18e6bc5e1aee8001/7c4f2/69873342_1143855435812336_7965630060092719104_o.webp 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0ff237757982b8ae18e6bc5e1aee8001/73b64/69873342_1143855435812336_7965630060092719104_o.jpg 300w", "/static/0ff237757982b8ae18e6bc5e1aee8001/3ad8d/69873342_1143855435812336_7965630060092719104_o.jpg 600w", "/static/0ff237757982b8ae18e6bc5e1aee8001/6c738/69873342_1143855435812336_7965630060092719104_o.jpg 1200w", "/static/0ff237757982b8ae18e6bc5e1aee8001/8b34c/69873342_1143855435812336_7965630060092719104_o.jpg 1800w", "/static/0ff237757982b8ae18e6bc5e1aee8001/8fe94/69873342_1143855435812336_7965630060092719104_o.jpg 2048w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0ff237757982b8ae18e6bc5e1aee8001/6c738/69873342_1143855435812336_7965630060092719104_o.jpg",
              "alt": "69873342 1143855435812336 7965630060092719104 o",
              "title": "69873342 1143855435812336 7965630060092719104 o",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><strong parentName="p">{`Chystáte rodinnú oslavu či svadbu? Oslavujte u nás!`}</strong></p>
      <p>{`V našej reštaurácií vás radi privítame a pripravíme pre vás výborné občerstvenie podľa vášho vlastného výberu.`}</p>
      <p><a parentName="p" {...{
          "href": "/svadby-a-rodinne-oslavy/"
        }}>{`Viac informácií`}</a></p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      